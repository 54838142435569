import client from "@/services/client";
import config from "../../config";

export default {
    async getPlatforms(payload, customConfig) {
        return await client.get('/platform/0', payload, {
            ...customConfig,
            baseURL: config.url.chirpleyApi
        })
    },
    async web3SignIn(payload) {
        return await client.post('/auth/web3/login', payload, {
            baseURL: config.url.chirpleyApi
        })
    },
    async web3SignUp(payload) {
        return client.parseResponse(await client.post('/auth/web3/register', payload, {
            baseURL: config.url.chirpleyApi
        }))
    },
    async authStepSignIn(payload) {
        return await client.post('/auth/step', payload, {
            baseURL: config.url.chirpleyApi
        })
    },
    async socialsSignIn(payload) {
        return await client.post(`/auth/${payload.type}/login?platform=chirppad`, payload, {
            baseURL: config.url.chirpleyApi
        })
    },
    async socialsSignUp(payload) {
        return await client.post(`/auth/${payload.type}/register?platform=chirppad`, payload, {
            baseURL: config.url.chirpleyApi
        })
    },
    async isSocialPlatformAlive(payload) {
        return await client.get(`/auth/platformalive?platform=${payload.platform}&scope=write`, {}, {
            ...payload.config,
            baseURL: config.url.chirpleyApi
        })
    },
    async signUp(payload) {
        return client.parseResponse(await client.post(`/auth/request?platform=chirppad`, payload, {
            baseURL: config.url.chirpleyApi
        }))
    },
    async authenticate(payload) {
        return client.parseResponse(await client.post('/login', payload))
    },
    async resetPassword(payload) {
        return await client.post('/auth/forgot', payload, {
            baseURL: config.url.chirpleyApi
        })
    },
    async signOut(payload) {
        return client.parseResponse(await client.post('/logout', payload))
    },
    async getProfile(payload) {
        return client.parseResponse(await client.get('/me', payload))
    },
    async updateProfile(payload) {
        return client.parseResponse(await client.upload('/me', payload.data, payload.files))
    },
    async initKYC(payload) {
        return client.parseResponse(await client.post('/me/kyc/init', payload))
    },
    async getKycStatus(payload) {
        return client.parseResponse(await client.get('/me/kyc/status', payload))
    },
    async getUserBalance(payload, customConfig) {
        return client.parseResponse(await client.get('/users/me/balance', payload, {
            ...customConfig,
            baseURL: config.url.chirpleyApi
        }))
    },
    async getUsersList(query, customConfig) {
        return client.parseResponse(await client.get(`/users/me/search?disable_campaigns=true&term=${query}`, {}, {
            ...customConfig,
            baseURL: config.url.chirpleyApi
        }))
    }
}