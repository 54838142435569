import client from "@/services/client";
import config from "@/config";

export default {
    async platformConnect(payload) {
        return client.parseResponse(await client.get(`/auth/${payload.platform}/connect`, payload.payload, {
            ...payload.config,
            baseURL: config.url.chirpleyApi
        }))
    },
    async instagramConnect(payload) {
        return client.parseResponse(await client.get('/auth/instagram/connect', payload.payload, {
            ...payload.config,
            baseURL: config.url.chirpleyApi
        }))
    },
    async tiktokConnect(payload) {
        return client.parseResponse(await client.get('/auth/tiktok/connect', payload.payload, {
            ...payload.config,
            baseURL: config.url.chirpleyApi
        }))
    },
    async facebookConnect(payload) {
        return client.parseResponse(await client.get('/auth/facebook/connect', payload.payload, {
            ...payload.config,
            baseURL: config.url.chirpleyApi
        }))
    },
    async xConnect(payload) {
        return client.parseResponse(await client.get('/auth/twitter/connect', payload.payload, {
            ...payload.config,
            baseURL: config.url.chirpleyApi
        }))
    },
    async getUserPlatforms(payload) {
        return client.parseResponse(await client.get('/users/me/platforms', payload.payload, {
            ...payload.config,
            baseURL: config.url.chirpleyApi
        }))
    }
}