import {createRouter, createWebHistory} from 'vue-router';
import authMiddleware from "@/router/middlewares/auth-middleware";
import authRedirect from "@/router/middlewares/auth-redirect-middleware";
import rolesMiddleware from "@/router/middlewares/roles-middleware";

// import store from '@/store';

const routes = [
    {
        path: '/redirect',
        component: () => import ( /* webpackChunkName: "main" */ '../views/auth/__template'),
        children: [{
            path: '',
            component: () => import ('../views/auth/Redirect'),
        }],
        meta: {
            guest: true
        }
    },
    {
        path: '/',
        component: () => import ( /* webpackChunkName: "register" */ '../views/__template'),
        children: [{
            path: '', component: () => import ('../views/View.vue'),
        }]
    },
    {
        path: '/launchpad',
        component: () => import ( /* webpackChunkName: "register" */ '../views/launchpad/__template'),
        children: [{
            path: '', component: () => import ('../views/launchpad/View.vue'),
        }]
    },
    {
        path: '/daily-tasks',
        component: () => import ( /* webpackChunkName: "register" */ '../views/daily-tasks/__template'),
        children: [{
            path: '', component: () => import ('../views/daily-tasks/View.vue'),
        }]
    },
    {
        path: '/login',
        component: () => import ( /* webpackChunkName: "register" */ '../views/auth/__template'),
        children: [{
            path: '',
            component: () => import ('../views/auth/Login'),
        }],
        meta: {
            guest: true
        }
    },
    {
        path: '/register',
        component: () => import ( /* webpackChunkName: "main" */ '../views/auth/__template'),
        children: [{
            path: '',
            component: () => import ('../views/auth/Register.vue'),
        }],
        meta: {
            guest: true
        }
    },
    {
        path: '/signup',
        component: () => import ( /* webpackChunkName: "signup" */ '../views/auth/__template'),
        children: [{
            path: '',
            component: () => import ('../views/auth/Signup.vue'),
        }],
        meta: {
            guest: true
        }
    },
    {
        path: '/password-forgot',
        component: () => import ( /* webpackChunkName: "main" */ '../views/auth/__template'),
        children: [{
            path: '',
            component: () => import ('../views/auth/Passwordforgot'),
        }],
        meta: {
            guest: true
        }
    },

    {
        path: '/password-reset',
        component: () => import ( /* webpackChunkName: "main" */ '../views/auth/__template'),
        children: [{
            path: '',
            component: () => import ('../views/auth/Passwordreset'),
        }],
        meta: {
            guest: true
        }
    },
    {
        path: '/account',
        name: 'account',
        component: () => import (/* webpackChunkName: "main" */ '@/views/account/__template.vue'),
        children: [
            {
                path: '',
                component: () => import ('../views/account/View.vue'),
                children: [
                    {
                        path: '',
                        name: 'account-portfolio',
                        component: () => import ('@/views/account/portfolio/View.vue'),
                        meta: {
                            auth: true,
                            roles: ['influencer', 'investor', 'brand', 'admin']
                        }
                    },
                    {
                        path: 'account-information',
                        name: 'account-information',
                        component: () => import ('@/views/account/account-information/View.vue'),
                        meta: {
                            auth: true
                        }
                    }
                ]
            }
        ]
    },
    {
        path: '/investor',
        name: 'investor',
        component: () => import (/* webpackChunkName: "main" */ '@/views/dashboards/__template.vue'),
        children: [
            {
                path: 'dashboard',
                name: 'investor-dashboard',
                component: () => import ('@/views/dashboards/investor/View.vue'),
                meta: {
                    auth: true,
                    roles: ['investor']
                }
            },
            {
                path: 'buy-allocation/:id',
                name: 'investor-buy-allocation',
                component: () => import ('@/views/investor/buy-allocation/View.vue'),
                meta: {
                    auth: true,
                    roles: ['investor']
                }
            },
        ]
    },
    {
        path: '/brand',
        name: 'brand',
        component: () => import (/* webpackChunkName: "main" */ '@/views/projects/brand/__template.vue'),
        children: [
            {
                path: 'projects/:id',
                name: 'brand-project-view',
                component: () => import ('@/views/projects/brand/project/View.vue'),
                children: [
                    {
                        path: '',
                        name: 'allocation-brand',
                        component: () => import ('../views/projects/brand/project/allocation/View.vue'),
                    },
                    {
                        path: 'details',
                        name: 'details-brand',
                        component: () => import ('../views/projects/brand/project/details/View.vue'),
                    },
                    {
                        path: 'ido-details',
                        name: 'ido-details-brand',
                        component: () => import ('../views/projects/brand/project/ido-details/View.vue'),
                    },
                    {
                        path: 'airdrop',
                        name: 'airdrop-brand',
                        component: () => import ('../views/projects/brand/project/airdrop/View.vue'),
                    }
                ],
                meta: {
                    auth: true,
                    roles: ['brand']
                }
            }
        ]
    },
    {
        path: '/admin',
        name: 'admin',
        component: () => import (/* webpackChunkName: "main" */ '@/views/projects/admin/__template.vue'),
        children: [
            {
                path: 'dashboard',
                name: 'admin-dashboard',
                component: () => import ('@/views/dashboards/admin/View.vue'),
                meta: {
                    auth: true,
                    roles: ['brand', 'admin']
                }
            },
            {
                path: 'project/launch',
                name: 'admin-launch-project',
                component: () => import ('@/views/projects/admin/launch/View.vue'),
                meta: {
                    auth: true,
                    roles: ['admin']
                }
            },
            {
                path: 'project/:id/edit',
                name: 'admin-edit-project',
                component: () => import ('@/views/projects/admin/launch/View.vue'),
                meta: {
                    auth: true,
                    roles: ['admin']
                }
            },
            {
                path: 'projects/:id',
                name: 'admin-project-view',
                component: () => import ('@/views/projects/admin/project/View.vue'),
                children: [
                    {
                        path: '',
                        name: 'details-admin',
                        component: () => import ('../views/projects/admin/project/details/View.vue'),
                    },
                    {
                        path: 'ido-details',
                        name: 'ido-details-admin',
                        component: () => import ('../views/projects/admin/project/ido-details/View.vue'),
                    },
                    {
                        path: 'daily-tasks',
                        name: 'daily-tasks-admin',
                        component: () => import ('../views/projects/admin/project/daily-tasks/View.vue'),
                    },
                    {
                        path: 'allocation',
                        name: 'allocation-admin',
                        component: () => import ('../views/projects/admin/project/allocation/View.vue'),
                    },
                    {
                        path: 'investors',
                        name: 'investors-admin',
                        component: () => import ('../views/projects/admin/project/investors/View.vue'),
                    },
                    {
                        path: 'airdrop',
                        name: 'airdrop-admin',
                        component: () => import ('../views/projects/admin/project/airdrop/View.vue'),
                    },
                    {
                        path: 'finalization',
                        name: 'finalization-admin',
                        component: () => import ('../views/projects/admin/project/finalization/View.vue'),
                    }
                ],
                meta: {
                    auth: true,
                    roles: ['admin']
                }
            }
        ]
    },
    {
        path: '/projects/:id',
        name: 'projects',
        component: () => import (/* webpackChunkName: "main" */ '../views/projects/__template'),
        children: [
            {
                path: '',
                component: () => import ('../views/projects/View.vue'),
                children: [
                    {
                        path: '',
                        name: 'details',
                        component: () => import ('@/views/projects/guest/details/View.vue'),
                    },
                    {
                        path: 'ido-details',
                        name: 'ido-details',
                        component: () => import ('@/views/projects/guest/ido-details/View.vue'),
                    },
                    {
                        path: 'daily-tasks',
                        name: 'daily-tasks',
                        component: () => import ('@/views/projects/guest/daily-tasks/View.vue'),
                    },
                    {
                        path: 'wallet-scan',
                        name: 'wallet-scan',
                        component: () => import ('@/views/projects/guest/wallet-scan/View.vue'),
                        meta: {
                            auth: true
                        }
                    },
                    {
                        path: 'airdrop',
                        name: 'airdrop',
                        component: () => import ('@/views/projects/guest/airdrop/View.vue'),
                        meta: {
                            auth: true
                        }
                    },
                    {
                        path: 'allocation',
                        name: 'allocation',
                        component: () => import ('@/views/projects/guest/allocation/View.vue'),
                        meta: {
                            auth: true
                        }
                    }
                ]
            }
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/login'
    }

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    let result = next();
    if (to.meta.auth) {
        result = authMiddleware(to, next);
        if (to.meta.roles && to.meta.roles.length) {
            result = rolesMiddleware(to, next)
        }
    }
    if (to.meta.guest) {
        result = authRedirect(to, next)
    }
    return result;
})

export default router;
