module.exports = {
    url: {
        chirpleyApi: 'https://api.chirpley.ai/v1',
        api: `https://api.chirppad.ai/api`,
        login: `https://launchpad.chirppad.ai`,
        main: `http://launchpad.chirppad.ai`,
        site: `https://chirppad.ai`,
        chirpley: `https://v3.chirpley.ai`,
        cdn: `https://cdn.chirpley.ai`,
    },
    cookiedomain: "launchpad.chirppad.ai",

    rules: {
        required: [(v) => !!v || 'Required field!'],
        name: [(v) => !!v || 'Required field!', (value) => (value && !(value.length > 100)) || 'Maximum 100 characters'],
        email: [(v) => !!v || 'Email address is not valid!', (v) => /.+@.+\..+/.test(v) || 'Invalid email address!'],
        phonenumber: [(v) => !!v || 'Phone number is not valid!', (v) => /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g.test(v) || 'Invalid phone number!'],

    },
    regex: {
        name: /^[a-zA-Z -]{2,50}$/,
        mail: /^.+@.+$/,
        phonenumber: /^\+?[1-9]\d{3,14}$/,
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\s\S])[ -~]{8,64}$/,
    },
    allowedredirectdomains: [
        'api.chirppad.ai',
        'chirppad.ai',
        'launchpad.chirppad.ai',
        'v3.chirpley.ai',
        'chirpley.ai',
        '*.chirpley.ai',
        '*.chirppad.ai',
        'localhost:8080',
        'localhost:8081',
        'localhost:8082',
        'localhost:8083',
        'localhost:8084',
        'localhost:8085',
        'localhost:8086',
    ],
    dateformat: {
        weekday: 'short',
        year: 'numeric',
        day: 'numeric',
        month: 'long',
        hour: 'numeric',
        minute: 'numeric'
    },
    auth: {
        maps: "AIzaSyB30vQq6BQslpi5frf6YMxoCKo1A2lIAoI"
    },
    crypto: {
        hot_wallet_address_mainnet: "0x553F175cEDc214f1DDBBd4000E662B26a719dFb2",
        hot_wallet_address_testnet: "0xFeC17c8387E65DEF143e6c93E7Cae52868d695d2",
        chrp_token_address_mainnet: "0xeD00Fc7D48B57B81FE65D1cE71c0985e4CF442CB",
        chrp_token_address_testnet: "0x25BD57a4465E1c0788EebA1Ea06fBB95bEf4F282",
        active_chain: "mainnet", //testnet / mainnnet
        walletconnect_project_id: "1feb78fde77c49d2a91cd70a3e1f8d29"
    }
}