import client from "@/services/client";
import config from "@/config";

export default {
    async getBlockchainNetworks(payload) {
        return client.parseResponse(await client.get('/chains', payload))
    },
    async getSocialPlatforms(payload) {
        return client.parseResponse(await client.get('/social-platforms', payload))
    },
    async getPlatforms(payload) {
        return client.parseResponse(await client.get('/platform/0', payload, {
            baseURL: config.url.chirpleyApi
        }))
    },
    async getServices(payload, customConfig) {
        return client.parseResponse(await client.get('/users/list/services', payload, {
            baseURL: config.url.chirpleyApi,
            ...customConfig
        }))
    }
}