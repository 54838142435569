import client from "@/services/client";

export default {
    async createProject(payload) {
        return client.parseResponse(await client.upload('/projects', payload.data, payload.files))
    },
    async updateProject(payload) {
        return client.parseResponse(await client.upload(`/projects/${payload.data.id}`, payload.data, payload.files))
    },
    async getProject(payload) {
        return client.parseResponse(await client.get(`/projects/${payload.id}`, payload))
    },
    async getProjects(payload) {
        return client.parseResponse(await client.get('/projects', payload, payload.config))
    },
    async getProjectWallets(payload) {
        return client.parseResponse(await client.get('/project-wallets', payload))
    },
    async calculateProjectTier(payload) {
        return client.parseResponse(await client.get(`/projects/${payload.id}/calculate-tier`, payload))
    },
    async getWallet(payload) {
        return client.parseResponse(await client.get(`/wallets/${payload.id}`, payload))
    },
    async updateProjectWalletAddress(payload) {
        return client.parseResponse(await client.put(`/wallets/${payload.id}`, payload))
    },
    async issueWalletRefund(payload) {
        return client.parseResponse(await client.post(`/project-wallets/${payload.id}/refunds`, payload))
    },
    async getVestings(payload) {
        return client.parseResponse(await client.get(`/projects/${payload.id}/vestings`, payload))
    },
    async sendAllocationTokens(payload) {
        return client.parseResponse(await client.post('/token-transfers', payload))
    },
    async deployInvestmentContract(payload) {
        return client.parseResponse(await client.post('/ido/deploy-investment', payload))
    },
    async deployDistributionContract(payload) {
        return client.parseResponse(await client.post('/vestings/deploy-distribution', payload))
    },
    async withdrawInvestmentContract(payload) {
        return client.parseResponse(await client.post('/ido/withdraw', payload))
    },
    async pauseInvestmentContract(payload) {
        return client.parseResponse(await client.post('/ido/pause-investment-contract', payload))
    },
    async unpauseInvestmentContract(payload) {
        return client.parseResponse(await client.post('/ido/unpause-investment-contract', payload))
    },
    async getInvestmentContractCurrencyBalance(payload) {
        return client.parseResponse(await client.get('/ido/balance-investment-contract', payload))
    },
    async getDistributionContractCurrencyBalance(payload) {
        return client.parseResponse(await client.get('/vestings/balance-distribution-contract', payload))
    },
    async getDistributionContractClaimableAmount(payload) {
        return client.parseResponse(await client.get('/vestings/claimable-amount', payload))
    },
    async getInvestmentContractPaused(payload) {
        return client.parseResponse(await client.get('/ido/paused-investment-contract', payload))
    },
    async createVestingDetail(payload) {
        return client.parseResponse(await client.post('/vestings-details', payload))
    },
    async updateVestingDetail(payload) {
        return client.parseResponse(await client.put(`/vestings-details/${payload.id}`, payload))
    },
    async deleteVestingDetail(payload) {
        return client.parseResponse(await client.delete(`/vestings-details/${payload.id}`, payload))
    },
    async getVestingDetails(payload) {
        return client.parseResponse(await client.get('/vestings-details', payload))
    },
    async getTokenTransfers(payload) {
        return client.parseResponse(await client.get('/token-transfers', payload))
    },
    async scanWallet(payload) {
        return client.parseResponse(await client.post('/wallets', payload))
    },
    async createProjectWallet(payload) {
        return client.parseResponse(await client.post('/project-wallets', payload))
    },
    async getAllocationDetailsStats(payload) {
        return client.parseResponse(await client.get(`/projects/${payload.id}/statistics`, payload))
    },
    async exportProjectWallets(payload) {
        return client.parseResponse(await client.post('/project-wallets/export', payload, {
            responseType: 'blob'
        }))
    },
    async exportAirdropLeaderboard(payload){
        return client.parseResponse(await client.post(`/airdrop/${payload.id}/leadership-export`, payload, {
            responseType: 'blob'
        }))
    },
    async getAirdropLeadership(payload) {
        return client.parseResponse(await client.get(`/airdrop/${payload.id}/leaderships`, payload))
    },
    async getAirdropLeadershipStatistics(payload) {
        return client.parseResponse(await client.get(`/airdrop/${payload.id}/leadership-statistics`, payload))
    }
}