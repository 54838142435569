import client from "@/services/client";
import config from "@/config";

export default {


    async acceptOffer(payload) {
        return client.parseResponse(await client.patch(`offers/${payload.id}/accept`,{}, {
            ...payload.config,
            baseURL: config.url.chirpleyApi
        }))
    },

    async declineOffer(payload) {
        return client.parseResponse(await client.patch(`offers/${payload.id}/decline`,{}, {
            ...payload.config,
            baseURL: config.url.chirpleyApi
        }))
    },
    
}