import client from "@/services/client";
import config from "@/config";

export default {

    async createCampaign(payload) {
        return client.parseResponse(await client.post('tasks/me', payload.payload, {
            ...payload.config,
            baseURL: config.url.chirpleyApi
        }))
    },

    async updateCampaign(id,payload) {
        return client.parseResponse(await client.post(`tasks/${id}/edit`, payload.payload, {
            ...payload.config,
            baseURL: config.url.chirpleyApi
        }))
    },

    async getCampaign(payload) {
        return client.parseResponse(await client.get(`tasks/${payload.id}`,{}, {
            ...payload.config,
            baseURL: config.url.chirpleyApi
        }))
    },

    async getParticipatedTasks(payload) {
        return client.parseResponse(await client.get(`tasks/${payload.id}/participated?chirppad=true&type=${payload.type}&page=${payload.page}&size=${payload.size}`,{}, {
            ...payload.config,
            baseURL: config.url.chirpleyApi
        }))
    },

    async getCampaignLeaderboard(payload) {
        return client.parseResponse(await client.get(`tasks/${payload.reference}/leaderboard?page=${payload.page}&size=${payload.size}`,{}, {
            ...payload.config,
            baseURL: config.url.chirpleyApi
        }))
    },

    async joinCampaign(payload) {
        return client.parseResponse(await client.post(`tasks/${payload.reference}/join`, payload.payload, {
            ...payload.config,
            baseURL: config.url.chirpleyApi
        }))
    },

    async withdrawCampaign(payload) {
        return client.parseResponse(await client.post(`tasks/${payload.reference}/withdraw`, payload.payload, {
            ...payload.config,
            baseURL: config.url.chirpleyApi
        }))
    },

    async getBrandContent(payload) {
        return client.parseResponse(await client.get(`tasks/${payload.id}/brandcontent`,{}, {
            ...payload.config,
            baseURL: config.url.chirpleyApi
        }))
    },

    async getCdnFile(payload) {
        return client.parseResponse(await client.get(`cdn/`,payload.payload, {
            ...payload.config,
            baseURL: config.url.chirpleyApi
        }))
    },

    async getTweet(payload) {
        return client.parseResponse(await client.get(`twitter/${payload.id}/get`,{}, {
            ...payload.config,
            baseURL: config.url.chirpleyApi
        }))
    },
    
}